import React from 'react';
import PropTypes from 'prop-types';
import { replaceSpecialChars, getShouldRequire } from '../../utils';
import {
	CheckboxInput,
	Input,
	Select,
	DateInput,
	Slider,
	MaskedInput,
	CreatableSelect,
	RadioInput,
	ReferenceInput,
	Textarea,
	AddressAutocomplete,
} from '../UI';
import IF from './IF';
import Label from '../UI/InputLabel';
import RadioInputTabs from '../UI/RadioInputTabs';

/**
 * @typedef GetShouldDisableParams
 * @property  {Boolean} isDisabled
 * @property  {Boolean} isEditorMode
 * @property  {Boolean} isEndorsable
 */

/**
 * @func getShouldDisable returns whether or not a field should be disabled.
 * If ion says it's disabled, it's always disabled. Otherwise, disable if in editor mode and field is not endorsable.
 * @param {GetShouldDisableParams} GetShouldDisableParams - { isDisabled, isEditorMode, isEndorsable }
 * @returns Boolean
 */
const getShouldDisable = ({ isDisabled, isEditorMode, isEndorsable }) => {
	if (typeof isDisabled === 'boolean') {
		return isDisabled;
	}
	return isEditorMode && !isEndorsable;
};

function RenderedField({
	children,
	id,
	description,
	isEditorMode = false,
	isEndorsable = false,
	isRequired = false,
	rules,
	register,
	control,
	trigger,
	Controller,
	getValues,
	getFieldState,
	flatApplication,
	setValue,
	variables,
	viewId,
	errors = {},
	setError,
	clearErrors,
	defaultValue,
	type,
	updateAppState,
	placesApiKey,
	tabPanels,
	uiDisplay: {
		placeholder,
		reference,
		increment,
		element = 'input',
		label,
		optionLabels,
		format,
		autocomplete,
		rows,
		disableCalendar,
		defaultOption,
		isDisabled,
		helpText,
		dynamicOptionLabels,
		fieldsToWatch,
		isHidden,
	} = {},
}) {
	const htmlId = replaceSpecialChars(id);
	const fieldObjectForDynamicRequire = {
		id,
		type,
		rules,
		isRequired,
	};

	const shouldRequire = getShouldRequire(fieldObjectForDynamicRequire, control);
	const shouldShowDescription = isEditorMode && Boolean(description);
	const shouldShowIsHiddenNote = isEditorMode && Boolean(isHidden);

	const sharedProps = {
		id,
		htmlId,
		label,
		errors: errors[id],
		isRequired: shouldRequire,
		isEndorsable,
		placeholder,
		isDisabled: getShouldDisable({ isDisabled, isEditorMode, isEndorsable }),
		helpText,
		getValues,
		variables,
		viewId,
		control,
		'aria-describedby': shouldShowDescription ? `${htmlId}-field-description` : undefined,
	};

	let shouldShowAsterisk = shouldRequire;
	if (element === 'CHECKBOX' && defaultValue !== undefined && type !== 'ARRAY') {
		// Remove asterisk if a single checkbox has a default value to eliminate user confusion.
		// ie. If a checkbox is required, but allowed to be false (and defaulted to false), we don't want users to think they have to check when they see an asterisk.
		shouldShowAsterisk = false;
	}

	const shouldUseSpecialRadioInputTabsElement = (element === 'RADIO') && tabPanels;

	const fieldToUse = (fieldInput) => {
		switch (fieldInput) {
			case 'INPUT':
				return (
					<Input
						{...sharedProps}
						register={register}
						autocomplete={autocomplete}
					/>
				);
			case 'ADDRESS_AUTOCOMPLETE':
				return (
					<AddressAutocomplete
						{...sharedProps}
						register={register}
						Controller={Controller}
						updateAppState={updateAppState}
						flatApplication={flatApplication}
						setValue={setValue}
						trigger={trigger}
						setError={setError}
						clearErrors={clearErrors}
						fieldsToWatch={fieldsToWatch}
						placesApiKey={placesApiKey}
					/>
				);
			case 'CHECKBOX': {
				return (
					<CheckboxInput
						{...sharedProps}
						register={register}
						optionLabels={optionLabels}
						defaultValue={defaultValue}
						trigger={trigger}
						type={type}
						dynamicOptionLabels={dynamicOptionLabels}
					/>
				);
			}
			case 'SELECT': {
				return (
					<Select
						{...sharedProps}
						register={register}
						autocomplete={autocomplete}
						optionLabels={optionLabels}
						defaultOption={defaultOption}
						dynamicOptionLabels={dynamicOptionLabels}
					>
						{children}
					</Select>
				);
			}
			case 'CREATABLE_SELECT': {
				return (
					<CreatableSelect
						{...sharedProps}
						register={register}
						autocomplete={autocomplete}
						optionLabels={optionLabels}
						flatApplication={flatApplication}
						getFieldState={getFieldState}
						setValue={setValue}
						Controller={Controller}
						updateAppState={updateAppState}
					/>
				);
			}
			case 'RADIO': {
				return (
					<>
						<IF condition={shouldUseSpecialRadioInputTabsElement}>
							<RadioInputTabs
								{...sharedProps}
								register={register}
								optionLabels={optionLabels}
								getValues={getValues}
								trigger={trigger}
								dynamicOptionLabels={dynamicOptionLabels}
								tabPanels={tabPanels}
							/>
						</IF>
						<IF condition={!shouldUseSpecialRadioInputTabsElement}>
							<RadioInput
								{...sharedProps}
								register={register}
								optionLabels={optionLabels}
								getValues={getValues}
								trigger={trigger}
								dynamicOptionLabels={dynamicOptionLabels}
							/>
						</IF>
					</>
				);
			}
			case 'DATE_PICKER':
				return (
					<DateInput
						{...sharedProps}
						register={register}
						autocomplete={autocomplete}
						Controller={Controller}
						flatApplication={flatApplication}
						getFieldState={getFieldState}
						updateAppState={updateAppState}
						getValues={getValues}
						setValue={setValue}
						rules={rules}
						disableCalendar={disableCalendar}
					/>
				);
			case 'REFERENCE':
				return (
					<ReferenceInput
						id={id}
						reference={reference}
						htmlId={htmlId}
						label={label}
						errors={errors[id]}
						register={register}
						control={control}
					/>
				);
			case 'SLIDER':
				return (
					<Slider
						{...sharedProps}
						register={register}
						getValues={getValues}
						min={rules.min}
						max={rules.max}
						increment={increment}
					/>
				);
			case 'MASKED_INPUT':
				return (
					<MaskedInput
						{...sharedProps}
						autocomplete={autocomplete}
						format={format}
						Controller={Controller}
						updateAppState={updateAppState}
						flatApplication={flatApplication}
						setValue={setValue}
						getFieldState={getFieldState}
					/>
				);
			case 'TEXTAREA':
				return (
					<Textarea
						{...sharedProps}
						register={register}
						autocomplete={autocomplete}
						rows={rows}
					/>
				);
			default:
				return (
					<Input
						{...sharedProps}
						register={register}
						autocomplete={autocomplete}
					/>
				);
		}
	};

	return (
		<div className="field-container" id={`${htmlId}-field-container`}>
			<Label
				labelFor={htmlId}
				isInvalid={Boolean(errors[id])}
				isRequired={shouldShowAsterisk}
				helpText={helpText}
				getValues={getValues}
				variables={variables}
				viewId={viewId}
				control={control}
			>
				{label}
			</Label>
			<IF condition={shouldShowIsHiddenNote}>
				{/* use description in app editor to provide more context */}
				<span id={`${htmlId}-is-hidden-warning`} className="is-hidden-warning">This field is sometimes hidden in the purchase flow, but is displayed here for context.</span>
			</IF>
			{ fieldToUse(element) }
			<IF condition={shouldShowDescription}>
				{/* use description in app editor to provide more context */}
				<span id={`${htmlId}-field-description`} className="field-description">{description}</span>
			</IF>
		</div>
	);
}

RenderedField.defaultProps = {
	children: [],
	isEndorsable: false,
	isRequired: false,
	rules: {},
	errors: {},
	helpText: undefined,
};

// TODO: come back and stub the array and object types below.
RenderedField.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.arrayOf(PropTypes.element),
	]),
	id: PropTypes.string.isRequired,
	isEndorsable: PropTypes.bool,
	isRequired: PropTypes.bool,
	helpText: PropTypes.string,
	// eslint-disable-next-line react/forbid-prop-types
	rules: PropTypes.object,
	register: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	control: PropTypes.object.isRequired,
	trigger: PropTypes.func.isRequired,
	Controller: PropTypes.func.isRequired,
	getValues: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	errors: PropTypes.object,
	updateAppState: PropTypes.func.isRequired,
};

export default RenderedField;
