import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../UI';
import IF from './IF';
import { NAV_ACTIONS } from '../../models/dictionary';

const StepThroughNav = ({
	backButtonLabel = 'Back',
	nextButtonLabel = 'Next',
	hideBack,
	hideNext,
	isLoading,
	navigate,
	status,
	onClickNext,
	onClickBack,
	disableNext,
	disableBack,
}) => {
	const handleNext = async () => {
		if (onClickNext) {
			await onClickNext();
			return;
		}
		navigate(NAV_ACTIONS.NEXT);
	};
	const handleBack = async () => {
		if (onClickBack) {
			await onClickNext();
			return;
		}
		navigate(NAV_ACTIONS.BACK);
	};
	return (
		<div id="nav-button-container">
			<IF condition={!hideNext}>
				<Button
					variant="primary"
					label={nextButtonLabel}
					onClick={handleNext}
					disabled={disableNext}
					isLoading={isLoading}
					id="next-button"
					data-content={`${status}-next-button`}
				/>
			</IF>
			<IF condition={!hideBack}>
				<Button
					variant="secondary"
					label={backButtonLabel}
					onClick={handleBack}
					disabled={disableBack}
					id="back-button"
					// added this here so we can grab buttons in css without changing the id
					data-content={`${status}-back-button`}
				/>
			</IF>
		</div>
	);
};

StepThroughNav.defaultProps = {
	nextButtonLabel: 'Next',
	hideBack: false,
	hideNext: false,
	onClickNext: undefined,
	onClickBack: undefined,
	disableNext: false,
	disableBack: false,
	isLoading: false,
};

StepThroughNav.propTypes = {
	nextButtonLabel: PropTypes.node,
	hideBack: PropTypes.bool,
	hideNext: PropTypes.bool,
	navigate: PropTypes.func.isRequired,
	onClickNext: PropTypes.func,
	onClickBack: PropTypes.func,
	disableNext: PropTypes.bool,
	disableBack: PropTypes.bool,
	isLoading: PropTypes.bool,
};

export default StepThroughNav;
